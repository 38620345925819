<template>
	<div class="invoice-data">
		<div class="header acea-row row-between-wrapper" ref="header">
	      <div class="black" @click="returnChange()">
	        <img src="@assets/images/icon_black.png" alt="" />
	      </div>
	      <p>发票</p>
	    </div>
	  	<div class="set-container">
			<div class="set-list acea-row row-between">
				<p>发票抬头</p>
				<p class="right">
					<input type="text" v-model="invoice_rice" placeholder="请输入发票抬头" >
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>税号</p>
				<p class="right">
					<input type="text" v-model="duty_number" placeholder="请输入税号" >
				</p>
			</div>

		</div>
		<div class="set-container" v-if="type==1">
			<div class="set-list acea-row row-between">
				<p>地址</p>
				<p class="right">
					<input type="text" v-model="address" placeholder="请输入地址" >
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>联系电话</p>
				<p class="right">
					<input type="text" v-model="phone" placeholder="请输入联系电话" >
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>开户银行</p>
				<p class="right">
					<input type="text" v-model="blank_name" placeholder="请输入开户银行" >
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>银行账号</p>
				<p class="right">
					<input type="text" v-model="blank_account" placeholder="请输入银行账号" >
				</p>
			</div>
		</div>


		<div class="detail-btn acea-row row-between">
	      <div class="right pay bule" :class="firstJum? 'glay':''" @click="save">
	        <p>保存</p>
	      </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
import { postSave,getInvoice } from "@api/user";
import {Verify_Empty,Verify_Mobile} from '@utils/dy_validate';
export default {
	// name:'Invoice',
	data(){
		return {
			firstJum:false,
			invoice_rice:'',
			duty_number:'',
			address:'',
			blank_name:'',
			blank_account:'',
			phone:'',
			type:this.$route.query.type,
		}
	},
	mounted(){
		this.getInvoice();
	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	    //点击提交
	    save(){
	    	var that = this;
	    	if(that.firstJum==false) {
	    		if(that.type==1) {
					if(Verify_Empty(that.invoice_rice)) {
			    		this.$dialog.toast({ mes: "请输入发票抬头" });
						return false;
			    	} else if(Verify_Empty(that.duty_number)) {
			    		this.$dialog.toast({ mes: "请输入税号" });
						return false;
			    	} else if(Verify_Empty(that.address)) {
			    		this.$dialog.toast({ mes: "请输入地址" });
						return false;
			    	} else if(Verify_Empty(that.blank_name)) {
			    		this.$dialog.toast({ mes: "请输入开户银行" });
						return false;
			    	} else if(Verify_Empty(that.blank_account)) {
			    		this.$dialog.toast({ mes: "请输入银行账号" });
						return false;
			    	} else if(Verify_Empty(that.phone)) {
			    		this.$dialog.toast({ mes: "请输入联系电话" });
						return false;
			    	} else if(!Verify_Mobile(that.phone)) {
			    		this.$dialog.toast({ mes: "请输入正确的手机号" });
						return false;
			    	}
	    		} else {
	    			if(Verify_Empty(that.invoice_rice)) {
			    		this.$dialog.toast({ mes: "请输入发票抬头" });
						return false;
			    	} else if(Verify_Empty(that.duty_number)) {
			    		this.$dialog.toast({ mes: "请输入税号" });
						return false;
			    	}
	    		}
				
		    	that.firstJum =  true;
		    	var data = {
		    		invoice_rice:that.invoice_rice,
					duty_number:that.duty_number,
					address:that.address,
					blank_name:that.blank_name,
					blank_account:that.blank_account,
					phone:that.phone,
					type:that.type,
		    	}
		    	postSave(data).then(res=>{
		    		console.log(res);
		    		that.firstJum = false;
		    		this.$dialog.success(res.msg);
		    	}).catch(res=>{
		    		that.firstJum =  false;
		    		 this.$dialog.error(res.msg);
		    	})

	    	} 

	    },
	    getInvoice(){
	    	var that = this;
	    	var data = {
	    		type:that.type,
	    	}
	    	getInvoice(data).then(res=>{
	    		console.log(res.data);
	    		that.invoice_rice = res.data.invoice_rice;
				that.duty_number = res.data.duty_number;
				that.address = res.data.address;
				that.blank_name = res.data.blank_name;
				that.blank_account = res.data.blank_account;
				that.phone = res.data.phone;
	    	}).catch(res=>{
	    		console.log(res);
	    	})
	    }
	}
}
</script>

<style type="text/css">
.invoice-data { padding-top:50px; }

.invoice-data .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.invoice-data .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.invoice-data .header .black img {
  width: 100%;
  height: 100%;
}
.invoice-data .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>


